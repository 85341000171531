import React, { useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import img1 from "../../pics/img1.jpg";
import img2 from "../../pics/img2.jpg";
import img3 from "../../pics/img3.jpg";
import img4 from "../../pics/img4.jpg";
import img5 from "../../pics/img5.jpg";
import img6 from "../../pics/img6.jpg";
import img7 from "../../pics/img7.jpg";
import img8 from "../../pics/img8.jpg";
import img9 from "../../pics/img9.jpg";
import img10 from "../../pics/77.jpg";
import img11 from "../../pics/778.jpg";
import img12 from "../../pics/99.jpg";
import img13 from "../../pics/bgt.jpg";
import img14 from "../../pics/ggd.jpg";
import img15 from "../../pics/mnm.jpg";
import img16 from "../../pics/pop.jpg";
import img17 from "../../pics/lil.jpg";
import "./Gallery.css";

const Gallery = () => {
  const images = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17
  ];

  const [selectedImageIndex, setSelectedImageIndex] = useState(null); // State for the selected image index

  // Function to open modal with selected image
  const openImageModal = (index) => {
    setSelectedImageIndex(index);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedImageIndex(null);
  };

  // Function to go to the next image
  const nextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to go to the previous image
  const prevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <section className="g-wrapper" id="gallery">
      <div className="g-container">
        <div className="r-head">
          <h1>Gallery</h1>
          <p>Swipe Right or Left</p>
        </div>
      </div>

      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        breakpoints={{
          480: { slidesPerView: 1 },
          600: { slidesPerView: 2 },
          750: { slidesPerView: 3 },
          1100: { slidesPerView: 4 },
        }}
      >
        <SliderButtons />
        <div className="gallery">
          {images.map((imgSrc, index) => (
            <SwiperSlide className="g-card" key={index}>
              <img
                src={imgSrc}
                alt={`Gallery Image ${index + 1}`}
                onClick={() => openImageModal(index)}
                style={{ cursor: "pointer" }} // Cursor change to indicate clickable
              />
            </SwiperSlide>
          ))}
        </div>
      </Swiper>

      {/* Modal for displaying full-screen image with navigation */}
      {selectedImageIndex !== null && (
        <div className="image-modal" onClick={closeModal}>
          <span className="close-modal">&times;</span>

          {/* Previous Image Button */}
          <button className="prev" onClick={(e) => { e.stopPropagation(); prevImage(); }}>
            &lt;
          </button>

          {/* Display selected image */}
          <img
            className="modal-content"
            src={images[selectedImageIndex]}
            alt={`Full Size Image ${selectedImageIndex + 1}`}
          />

          {/* Next Image Button */}
          <button className="next" onClick={(e) => { e.stopPropagation(); nextImage(); }}>
            &gt;
          </button>
        </div>
      )}
    </section>
  );
};

export default Gallery;

const SliderButtons = () => {
  const swiper = useSwiper();
  return (
    <div className="r-buttons">
      <button className="" onClick={() => swiper.slidePrev()}>&lt;</button>
      <button className="" onClick={() => swiper.slideNext()}>&gt;</button>
    </div>
  );
};
