import "./App.css"
import Biography from "./components/Biography/Biography";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Gallery from "./components/Gallery/Gallery";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Services from "./components/Services/Services";



function App() {
  return (
    <div className="App">
    <div className="head">
       <Header/>
       <Hero />
    </div>
    <Biography />
    <Gallery />
    <Services />
    <Contact />
    <Footer />
    </div>
  );
}

export default App;
