import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <section className="hero-wrapper" id="home">
      <div className="hero-container">
        <div className="hero-content">
          <h2>
            Charlize Theron
          </h2>
          <p>
            Welcome Dear Fans and Lovers! Thank you for the love all through
            the years. I love you all and look forward to connecting with each
            and every one of you. Gracias Amigos!
          </p>
          <a href="https://t.me/charlizetheronmanagement12">
            <div className="btn">
              Connect Now
            </div>
          </a>
        </div>
      </div>
      <div className="hero-left"></div>
    </section>
  );
};

export default Hero;
