import React, { useState } from 'react';
import './Header.css';

const Header = () => {
    const [burgerClass, setBurgerClass] = useState("hamburger unclicked");
    const [menuClass, setMenuClass] = useState("menu hidden");
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        if (!isOpen) {
            setBurgerClass("hamburger clicked");
            setMenuClass("menu visible");
        } else {
            setBurgerClass("hamburger unclicked");
            setMenuClass("menu hidden");
        }
        setIsOpen(!isOpen);
    };

    return (
        <section className="h-wrapper">
            <div className="h-container">
                {/* Left side */}
                <div className="h-left">
                    <div className="logo">Charlize Theron</div>
                </div>

                {/* Desktop Navigation Menu */}
                <ul className="desktop-menu">
                    <a href="">Home</a>
                    <a href="#services">Services</a>
                    <a href="#gallery">Gallery</a>
                    <a href="#contact">Contact</a>
                </ul>

                {/* Hamburger Icon for smaller screens */}
                <div className={burgerClass} onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                {/* Mobile Dropdown Menu */}
                <ul className={`mobile-menu ${isOpen ? 'show' : ''}`}>
                    <a href="">Home</a>
                    <a href="#services">Services</a>
                    <a href="#gallery">Gallery</a>
                    <a href="#contact">Contact</a>
                </ul>
            </div>
        </section>
    );
};

export default Header;
