import React from 'react'
import "./Contact.css"
const Contact = () => {
  return (
    <section className="c-wrapper" id='contact'>
        <div className="c-container">
            <div className="c-left">

            </div>

            <div className="c-right">
                <h2>Contact Me</h2>
                <form action="#">
                    <label htmlFor="">Name</label>
                    <input type="text" />
                    <label htmlFor="">Email</label>
                    <input type="text" />
                    <label htmlFor="">Subject</label>
                    <input type="text" />
                    <label htmlFor="">Message</label>
                    <textarea name="" id=""></textarea>
                    <button>
                        <a href="https://t.me/charlizetheronmanagement12">SEND MESSAGE</a>
                    </button>
                </form>
            </div>
        </div>
    </section>
  )
}

export default Contact