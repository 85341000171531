import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="f-wrapper">
      <div className="foot">
        <div className="footer-links">
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#gallery">Gallery</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
        <div className="footer-contact">
          <h3>Contact Us</h3>
          <p>Charlizetheronmanagement543@gmail.com</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
