import React from 'react'
import bio from "../../pics/biography.jpg"
import "./Biography.css"
const Biography = () => {
  return (
    <section className="b-wrapper">
        <div className="b-container">
            <h1>A Brief Biography of <br/>Charlize theron</h1>
            <div className="biography-container">
            <div className="b-left">
                <img src={bio} alt="" />
           </div>
            {/* Right Side */}
            <div className="b-right">
                <p>Charlize Theron, born on August 7, 1975, is a South African-American actress and producer. Known as one of the highest-paid actresses in the world, she has won prestigious awards including an Academy Award and a Golden Globe. Theron gained international fame in the 1990s with leading roles in films like *The Devil's Advocate*, *Mighty Joe Young*, and *The Cider House Rules*. She received critical acclaim and an Academy Award for portraying serial killer Aileen Wuornos in *Monster* (2003), making her the first South African to win an acting Oscar.

Theron has appeared in numerous successful action films such as *The Italian Job*, *Hancock*, *Mad Max: Fury Road*, *Atomic Blonde*, and *The Old Guard*. She earned additional Academy Award nominations for her performances in *North Country*, *Young Adult*, *Tully*, and *Bombshell*. Besides acting, she launched her own production company, Denver and Delilah Productions, and has produced several films in which she also starred. Theron became a U.S. citizen in 2007 and was honored with a star on the Hollywood Walk of Fame.</p>
            </div>
            
            </div>
            {/* Left Side */}

           
        </div>
    </section>
  )
}

export default Biography