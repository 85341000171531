import React from 'react'
import "./Services.css"

const Services = () => {
  return (
    <section className="s-wrapper" id='services'>
        <div className="s-container">
            <h1>Our Services</h1>
            <div className="service-container" >
                <div className="service-card" id='bed'>
                   <h2>Meet and Greet <br />
                    <span className='price'>
                       <span className="ye">Package:</span> $5000
                    </span>
                   </h2>

                   <p>This gives an opportunity to meet with your <br /> icon at a designated place and time</p>
                   <a href="https://t.me/charlizetheronmanagement12" className='service-btn'>Book Now</a>
                   
                </div>
                <div className="service-card" id='bed2'>
                <h2>Virtual Experience <br />
                    <span className='price'>
                       <span className="ye">Package:</span> $3000
                    </span>
                   </h2>

                   <p>Do you wish to contact your icon privately on a Virtual platform?<br />This exclusive offer includes access to face times, video calls and <br />personal contact information.</p>
                   <a href="https://t.me/charlizetheronmanagement12" className='service-btn'>Book Now</a>
                </div>
                <div className="service-card" id='bed3'>
                <h2>Items and Signings <br />
                    <span className='price'>
                       <span className="ye">Package:</span> $300
                    </span>
                   </h2>

                   <p>Do you wish to buy mugs, shirts, socks and other Theron items?
                   <br /> would you like a signature from Charlize Theron?</p>
                   <a href="https://t.me/charlizetheronmanagement12" className='service-btn'>Book Now</a>
                   
                </div>
                <div className="service-card" id='bed4'>
                <h2>Donate to support CTAOP<br />
                    
                   </h2>

                   <p>Join Charlize Theron and millions worldwide <br />to support Children in need today.</p>
                   <a href="https://t.me/charlizetheronmanagement12" className='service-btn'>Donate Now</a>
                   
                </div>
            </div>
        </div>
    </section>
  )
}

export default Services